import { UiContext } from '@/contexts/UiContext'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

const BackgroundImage = styled(Image)`
  position: absolute;
`

const Page = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 1080px;
  width: 1920px;
  background-size: contain;
  color: var(--on-dark);
`

export default function LaunchScreen() {
  const userService = useContext(UserServiceContext)
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const router = useRouter()
  const { forceUpdate } = useContext(UiContext)

  useEffect(() => {
    // We _must_ have a user service with a valid current user, and a valid config before we can do anything
    if (userService.currentUser && currentConfig) {
      setIsLoaded(true)
    }
  }, [userService.currentUser, currentConfig])

  useEffect(() => {
    // don't do anything until we're loaded
    if (!isLoaded) {
      return
    }
    if (forceUpdate) {
      return
    }
    if (userService.isLoggedIn) {
      router.replace('/home')
    }
  }, [isLoaded, router, userService.isLoggedIn, forceUpdate])

  return (
    <Page>
      <BackgroundImage src="/splash-screen.png" alt="" width="1920" height="1080" priority={true} />
    </Page>
  )
}
